<template>
  <form class="" @submit.prevent="onSubmit">
    <div v-if="firstRow" class="row">
      <!-- <div class="row"> -->
      <div
        v-if="search"
        class="col-sm-12 mb-h"
        :class="{
          'col-lg': firstRow < 4,
          'col-lg-6': firstRow === 4,
          '':
            smallHeightForCustomMultiselectAndSelectComponents === true
        }"
      >
<!--        <label>&nbsp;</label>-->
        <div class="input-group input-group-sm filters__input filters__input--clients">
          <span class="input-group-addon">
            <i class="fa fa-search"></i>
          </span>
          <input
            id="search"
            name="search"
            :placeholder="$t('general.search')"
            class="form-control"
            :value="value.search"
            ref="search"
            @input="selectInFiltersWithDelay"
          />
          <span v-show="value.search !== ''" class="clear-input text-muted">
            <i class="fa fa-remove" @click="searchClear"></i>
          </span>
        </div>
      </div>

      <div v-if="location"
           class="col-xs-12 mb-h input-group-for-location-select-in-TablePaginationV3ForPortalSessions--filters portal-pagination-filters-custom-margins-for-first-filters-row col-sm-12 mb-h"
           :class="{
          'col-lg': firstRow < 4,
          'col-lg-6': firstRow === 4,
          '':
            smallHeightForCustomMultiselectAndSelectComponents === true
        }">

              <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': value.location.data}"></i>
                </span>
        <SelectComponentV3ForUseInLocationSelectors
          :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
          v-if="showLocationFilter"
          no-wrap-selected-option
          class="select-location-in-TablePaginationV3ForPortalSessions--filters"
          enable-max-option-width
          small-size
          :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
          v-model="value.location.data"
          track-by="name"
          option-id-name="id"
          :no-options-found="$t('general.noResultForSearch')"
          :async-function="requestLocations.bind(this)"
          :placeholder="$t('portalStats.selectLocation')"
          @select="selectLocationInFilters"
        >
          <template #dropdown-start>
            <div class="ml-h mt-1">
              <Switch-component
                v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                :disabled="isDisabledWithChilds"
                @input="toggleWithChilds"
                :label="$t('general.withChild')"
                class=""
                v-model="value.location.with_childs"
              />
            </div>
          </template>
        </SelectComponentV3ForUseInLocationSelectors>
      </div>

      <div
        v-if="sort"
        class="mb-h"
        :class="{
          'col-lg col-sm': firstRow < 4,
          'col-lg-2 col-sm-4': firstRow === 4,
          'portal-pagination-filters-custom-margins-for-first-filters-row':
            smallHeightForCustomMultiselectAndSelectComponents === true
        }"
      >
        <div class="input-group flex-column">
<!--          <label for="filter" class="mb-0">-->
<!--            <small>{{ $t('general.filtersSort') }}</small>-->
<!--          </label>-->
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i
                class="text-primary fa"
                :class="[value.sort_order === 1 ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc']"
                @click="selectInFilters({ sort_order: true })"
              />
            </span>
            <select
              id="filter"
              class="form-control form-control-sm"
              ref="sort_by"
              :value="value.sort_by"
              @change="selectInFilters"
            >
              <option v-for="option in options" :key="option.value" :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        v-if="limit"
        class="mb-h"
        :class="{
          'col-lg col-sm': firstRow < 4,
          'col-lg-2 col-sm-4': firstRow === 4,
          'portal-pagination-filters-custom-margins-for-first-filters-row':
            smallHeightForCustomMultiselectAndSelectComponents === true
        }"
      >
        <div class="input-group flex-column">
<!--          <label for="page-limit" class="mb-0">-->
<!--            <small>{{ $t('general.filtersPageLimit') }}</small>-->
<!--          </label>-->
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i class="fa fa-list" />
            </span>
            <input
              id="page-limit"
              name="page-limit"
              type="number"
              class="form-control"
              ref="limit"
              :value="value.limit"
              @input="selectInFiltersWithDelay"
            />
          </div>
        </div>
      </div>
      <div
        v-if="offset"
        class="mb-h"
        :class="{
          'col-lg col-sm': firstRow < 4,
          'col-lg-2 col-sm-4': firstRow === 4,
          'portal-pagination-filters-custom-margins-for-first-filters-row':
            smallHeightForCustomMultiselectAndSelectComponents === true
        }"
      >
        <div class="input-group flex-column">
          <label for="page-offset" class="mb-0">
            <small>{{ $t('general.filtersPageOffset') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i class="fa fa-list-ol" />
            </span>
            <input
              id="page-offset"
              name="page-offset"
              type="number"
              class="form-control"
              ref="offset"
              :value="value.offset"
              @input="updateData"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="secondRow" class="row">
<!--      <div v-if="profile" class="col-xs-12 mb-h" :class="{ 'col-lg': secondRow < 2, 'col-lg-6': secondRow === 2 }">-->
<!--        &lt;!&ndash;        <br />&ndash;&gt;-->
<!--        <Multi-select-component-->
<!--          v-if="showProfilesFilter"-->
<!--          class="multiselect-in-portal-session-filters"-->
<!--          option-id-name="id"-->
<!--          track-by="name"-->
<!--          :placeholder="$t('portalStats.selectProfile')"-->
<!--          :async-function="requestProfiles.bind(this)"-->
<!--          v-model="value.profiles"-->
<!--        />-->
<!--      </div>-->
            <div v-if="profile" class="col-xs-12 mb-h col-lg-3" style="z-index: 9999">
                  <div class="input-group input-group-sm w-100">
            <span class="input-group-addon">
              <i class="fa fa-file-o" />
            </span>

        <Multi-select-component
          :class="{
            'multiselect-in-portal-pagination-for-sessions-filterss': smallHeightForCustomMultiselectAndSelectComponents === true,
            'w-100': true
          }"
          option-id-name="id"
          track-by="name"
          :placeholder="$t('portalStats.selectProfile')"
          :async-function="requestProfiles.bind(this)"
          v-model="value.profiles"
          @select="selectInFilters"
        />
                  </div>
      </div>





<!--      <div v-if="location" class="col-xs-12 mb-h input-group-for-location-select-in-TablePaginationV3ForPortalSessions&#45;&#45;filters" :class="{ 'col-lg': secondRow < 2, 'col-lg-6': secondRow === 2 }">-->
<!--&lt;!&ndash;        &lt;!&ndash;        <br />&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;        <SelectComponentV2&ndash;&gt;-->
<!--&lt;!&ndash;          @select="selectLocation"&ndash;&gt;-->
<!--&lt;!&ndash;          v-if="showLocationsFilter"&ndash;&gt;-->
<!--&lt;!&ndash;          class="select-in-portal-session-filters"&ndash;&gt;-->
<!--&lt;!&ndash;          v-model="value.location.data"&ndash;&gt;-->
<!--&lt;!&ndash;          track-by="name"&ndash;&gt;-->
<!--&lt;!&ndash;          option-id-name="id"&ndash;&gt;-->
<!--&lt;!&ndash;          :async-function="requestLocations.bind(this)"&ndash;&gt;-->
<!--&lt;!&ndash;          :placeholder="$t('portalStats.selectLocation')"&ndash;&gt;-->
<!--&lt;!&ndash;        >&ndash;&gt;-->
<!--&lt;!&ndash;          <template #dropdown-start>&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="select__option select__option_clickable" @click="toggleWithChilds">&ndash;&gt;-->
<!--&lt;!&ndash;              <Checkbox-component&ndash;&gt;-->
<!--&lt;!&ndash;                class="w-100"&ndash;&gt;-->
<!--&lt;!&ndash;                :disabled="isDisabledWithChilds"&ndash;&gt;-->
<!--&lt;!&ndash;                name="with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                :label="$t('portalStats.selectLocationWithChild')"&ndash;&gt;-->
<!--&lt;!&ndash;                id="with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                :checked="value.location.with_childs === true"&ndash;&gt;-->
<!--&lt;!&ndash;                @click="toggleWithChilds"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </span>&ndash;&gt;-->
<!--&lt;!&ndash;          </template>&ndash;&gt;-->
<!--&lt;!&ndash;        </SelectComponentV2>&ndash;&gt;-->
<!--                                                                                         <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': value.location.data}"></i>-->
<!--                </span>-->
<!--                  <SelectComponentV2-->
<!--                    v-if="showLocationFilter"-->
<!--                    no-wrap-selected-option-->
<!--                    class="select-location-in-TablePaginationV3ForPortalSessions&#45;&#45;filters"-->
<!--                    enable-max-option-width-->
<!--                    small-size-->
<!--                    show-delete-button-->
<!--                    v-model="value.location.data"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :no-options-found="$t('general.noResultForSearch')"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="selectLocationInFilters"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <div class="ml-h mt-1">-->
<!--                        <Switch-component-->
<!--                           :disabled="isDisabledWithChilds"-->
<!--                          @input="toggleWithChilds"-->
<!--                          :label="$t('general.withChild')"-->
<!--                          class=""-->
<!--                           v-model="value.location.with_childs"-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->
<!--      </div>-->
    </div>
    <!--    <div v-if="thirdRow" class="row">-->
    <!--      <div v-if="city" class="mb-h" :class="{ 'col-lg col-sm': thirdRow < 4, 'col-lg-3 col-sm-6': thirdRow === 4 }">-->
    <!--        <div class="input-group flex-column">-->
    <!--          <label for="filter-city" class="mb-0 text-nowrap">-->
    <!--            <small>{{ $t('general.filtersCity') }}</small>-->
    <!--          </label>-->
    <!--          <div class="input-group input-group-sm">-->
    <!--            <span class="input-group-addon">-->
    <!--              <i class="fa fa-home" />-->
    <!--            </span>-->
    <!--            <input-->
    <!--              id="filter-city"-->
    <!--              name="filter-city"-->
    <!--              type="text"-->
    <!--              class="form-control"-->
    <!--              ref="filter-city"-->
    <!--              v-model="q.city"-->
    <!--              placeholder="Vorkuta"-->
    <!--            />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div v-if="homeTown" class="mb-h" :class="{ 'col-lg col-sm': thirdRow < 4, 'col-lg-3 col-sm-6': thirdRow === 4 }">-->
    <!--        <div class="input-group flex-column">-->
    <!--          <label for="filter-home-town" class="mb-0 text-nowrap">-->
    <!--            <small>{{ $t('general.filtersHomeTown') }}</small>-->
    <!--          </label>-->
    <!--          <div class="input-group input-group-sm">-->
    <!--            <span class="input-group-addon">-->
    <!--              <i class="fa fa-home" />-->
    <!--            </span>-->
    <!--            <input-->
    <!--              id="filter-home-town"-->
    <!--              name="filter-home-town"-->
    <!--              type="text"-->
    <!--              class="form-control"-->
    <!--              ref="filterHomeTown"-->
    <!--              placeholder="London"-->
    <!--              v-model="q.home_town"-->
    <!--            />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div v-if="birthday" class="mb-h" :class="{ 'col-lg col-sm': thirdRow < 4, 'col-lg-3 col-sm-6': thirdRow === 4 }">-->
    <!--        <div class="input-group flex-column">-->
    <!--          <label for="year-of-birth" class="mb-0 text-nowrap">-->
    <!--            <small>{{ $t('general.filtersYearOfBirth') }}</small>-->
    <!--          </label>-->
    <!--          <div class="input-group input-group-sm">-->
    <!--            <span class="input-group-addon">-->
    <!--              <i class="fa fa-birthday-cake" />-->
    <!--            </span>-->
    <!--            <input-->
    <!--              id="year-of-birth"-->
    <!--              name="year-of-birth"-->
    <!--              type="text"-->
    <!--              class="form-control"-->
    <!--              placeholder="1994"-->
    <!--              v-model.number="q['date_of_birth_struct.year']"-->
    <!--              v-mask="'####'"-->
    <!--            />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div v-if="sex" class="mb-h" :class="{ 'col-lg  col-sm': thirdRow < 4, 'col-lg-3 col-sm-6': thirdRow === 4 }">-->
    <!--        <div class="input-group flex-column">-->
    <!--          <label for="filter-gender" class="mb-0 text-nowrap">-->
    <!--            <small>{{ $t('general.filtersGender') }}</small>-->
    <!--          </label>-->
    <!--          <div class="input-group input-group-sm">-->
    <!--            <span class="input-group-addon">-->
    <!--              <i class="fa fa-venus-mars" />-->
    <!--            </span>-->
    <!--            <select id="filter-gender" class="form-control form-control-sm limit-select-height" v-model="q.sex">-->
    <!--              <option-->
    <!--                v-for="gender of GENDERS"-->
    <!--                :key="gender"-->
    <!--                :value="gender === 'unset' ? '' : gender"-->
    <!--                :selected="gender === 'unset'"-->
    <!--              >-->
    <!--                {{ $t(`userAccount.gender.${gender}`) }}-->
    <!--              </option>-->
    <!--            </select>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div v-if="fourthRow" class="row flex-row">-->
    <!--&lt;!&ndash;      <div v-if="true" class="mb-h" :class="{ 'col-lg-3 col-sm': fourthRow < 4, 'col-lg-2 col-sm-3': fourthRow === 4 }">&ndash;&gt;-->
    <!--&lt;!&ndash;        <br />&ndash;&gt;-->
    <!--&lt;!&ndash;        <button class="btn btn-primary btn-sm btn-block" type="submit">&ndash;&gt;-->
    <!--&lt;!&ndash;          {{ $t('general.applyFilters') }}&ndash;&gt;-->
    <!--&lt;!&ndash;        </button>&ndash;&gt;-->
    <!--&lt;!&ndash;      </div>&ndash;&gt;-->
    <!--      <slot name="before-submit" />-->
    <!--      <div  class="mb-h col-lg-4" >-->
    <!--        <div class="input-group flex-column ">-->
    <!--          <label for="filter-social" class="mb-0 text-nowrap">-->
    <!--            <small>{{ $t('portalSessions.stateTableCaption') }}</small>-->
    <!--          </label>-->
    <!--          <div class="input-group input-group-sm">-->
    <!--            <span @click="handleChangeSeassionStateEqualNotEqual" class="input-group-addon" role="button">-->
    <!--              <i class="fa" v-if="sessionStateEqualNotEqual === '$eq'" > = </i>-->
    <!--              <i class="fa" v-if="sessionStateEqualNotEqual === '$ne'"> != </i>-->
    <!--            </span>-->
    <!--            <select-->
    <!--              id="filter-session-state"-->
    <!--              class="form-control form-control-sm limit-select-height"-->
    <!--              v-model="q.state"-->
    <!--            >-->
    <!--              <option-->
    <!--                v-for="state of SESSION_STATES"-->
    <!--                :key="state"-->
    <!--                :value="state === 'unset' ? '' : state"-->
    <!--                :selected="state === 'unset'"-->
    <!--              >-->
    <!--                {{ $t(`portalSessions.stateType${state}`) }}-->
    <!--              </option>-->
    <!--            </select>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        </div>-->
    <!--            <div  class="mb-h col-lg-4" >-->
    <!--        <div class="input-group flex-column">-->
    <!--          <label for="filter-social" class="mb-0 text-nowrap">-->
    <!--            <small>{{ $t('portalSessions.authenStateTableCaption') }}</small>-->
    <!--          </label>-->
    <!--          <div class="input-group input-group-sm">-->
    <!--            <span @click="handleChangeAuthenStateEqualNotEqual" class="input-group-addon" role="button">-->
    <!--              <i class="fa" v-if="authenStateEqualNotEqual === '$eq'" > = </i>-->
    <!--              <i class="fa" v-if="authenStateEqualNotEqual === '$ne'"> != </i>-->
    <!--            </span>-->
    <!--            <select-->
    <!--              id="filter-autheN-state"-->
    <!--              class="form-control form-control-sm limit-select-height"-->
    <!--              v-model="q.AuthenState"-->
    <!--            >-->
    <!--              <option-->
    <!--                v-for="state of AUTHN_STATESES"-->
    <!--                :key="state"-->
    <!--                :value="state === 'unset' ? '' : state"-->
    <!--                :selected="state === 'unset'"-->
    <!--              >-->
    <!--                {{ $t(`portalSessions.authenState${state}`) }}-->
    <!--              </option>-->
    <!--            </select>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--      </div>-->

    <!--                  <div  class="mb-h col-lg-4" >-->
    <!--        <div class="input-group flex-column">-->
    <!--          <label for="filter-social" class="mb-0 text-nowrap">-->
    <!--            <small>{{ $t('portalSessions.authenTypeTableCaption') }}</small>-->
    <!--          </label>-->
    <!--          <div class="input-group input-group-sm">-->
    <!--            <span @click="handleChangeAuthenTypeEqualNotEqual" class="input-group-addon" role="button">-->
    <!--              <i class="fa" v-if="authenTypeEqualNotEqual === '$eq'" > = </i>-->
    <!--              <i class="fa" v-if="authenTypeEqualNotEqual === '$ne'"> != </i>-->
    <!--            </span>-->
    <!--            <select-->
    <!--              id="filter-AuthN-type"-->
    <!--              class="form-control form-control-sm limit-select-height"-->
    <!--              v-model="q.authenticationType"-->
    <!--            >-->
    <!--              <option-->
    <!--                v-for="type of AUTHN_TYPES"-->
    <!--                :key="type"-->
    <!--                :value="type === 'unset' ? '' : type"-->
    <!--                :selected="type === 'unset'"-->
    <!--              >-->
    <!--                {{ $t(`portalSessions.authenType${type}`) }}-->
    <!--              </option>-->
    <!--            </select>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--      </div>-->

    <!--      <div v-if="visits" class="mb-h col-lg-4">-->
    <!--        <div class="input-group flex-column">-->
    <!--          <label for="visits-all" class="mb-0 text-nowrap">-->
    <!--            <small>{{ $t('general.filtersVisitsAll') }}</small>-->
    <!--          </label>-->
    <!--          <div class="input-group input-group-sm">-->
    <!--            <span class="input-group-addon" role="button" @click="handleChangeVisitsGreaterLessOperator">-->
    <!--              <i class="fa" v-tooltip.auto="{ content: $t(`misc.${visitsGreaterLessOperator}`) }">-->
    <!--                {{ visitsGreaterLessOperator === '$gt' ? '>' : '' }}-->
    <!--                {{ visitsGreaterLessOperator === '$lt' ? '<' : '' }}-->
    <!--                {{ visitsGreaterLessOperator === '$eq' ? '=' : '' }}-->
    <!--              </i>-->
    <!--            </span>-->

    <!--            <input-->
    <!--              id="visits-all"-->
    <!--              name="visits-all"-->
    <!--              type="text"-->
    <!--              class="form-control"-->
    <!--              placeholder="4"-->
    <!--              v-mask="'#########'"-->
    <!--              v-model.number="q['visits.all'][visitsGreaterLessOperator]"-->
    <!--            />-->
    <!--            &lt;!&ndash; <span class="clear-input text-muted">-->
    <!--                  <i class="fa fa-remove" @click="filters.q['visits.all'] = {}"></i>-->
    <!--                </span> &ndash;&gt;-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div v-if="date" class="mb-h col-lg-4" >-->
    <!--        <div class="input-group input-group-sm flex-column">-->
    <!--          <label for="datepicker-label" class="mb-0">-->
    <!--            <small>{{ $t('general.feltersDateRange') }}</small>-->
    <!--          </label>-->
    <!--          <div class="input-group input-group-sm">-->
    <!--            <span class="input-group-addon">-->
    <!--              <i class="fa fa-calendar" />-->
    <!--            </span>-->
    <!--            <date-picker-->
    <!--              class="w-100 text-muted"-->
    <!--              v-model="value.date"-->
    <!--              type="date"-->
    <!--              input-class="mx-input datepicker_height_27 text-left input-group-sm"-->
    <!--              :show-icon="false"-->
    <!--            ></date-picker>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div v-if="true" class="row flex-row">
      <!--      <div v-if="true" class="mb-h" :class="{ 'col-lg-3 col-sm': fourthRow < 4, 'col-lg-2 col-sm-3': fourthRow === 4 }">-->
      <!--        <br />-->
      <!--        <button class="btn btn-primary btn-sm btn-block" type="submit">-->
      <!--          {{ $t('general.applyFilters') }}-->
      <!--        </button>-->
      <!--      </div>-->
      <slot name="before-submit" />
      <div class="mb-h col-lg-2">
        <div class="input-group flex-column">
          <label for="filter-session-state" class="mb-0 text-nowrap">
            <small>{{ $t('portalSessions.stateTableCaption') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span @click="handleChangeSeassionStateEqualNotEqual" class="input-group-addon" role="button">
              <i class="fa filters-eq-not-eq" v-if="sessionStateEqualNotEqual === '$eq'">=</i>
              <i class="filters-eq-not-eq" v-if="sessionStateEqualNotEqual === '$ne'">&#8800;</i>
            </span>
            <select
              @change="selectInFilters"
              id="filter-session-state"
              class="form-control form-control-sm limit-select-height"
              v-model="q.state"
            >
              <option
                v-for="state of SESSION_STATES"
                :key="state"
                :value="state === 'unset' ? '' : state"
                :selected="state === 'unset'"
              >
                {{ $t(`portalSessions.stateType${state}`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="mb-h col-lg-2">
        <div class="input-group flex-column">
          <label for="filter-autheN-state" class="mb-0 text-nowrap">
            <small>{{ $t('portalSessions.authenStateTableCaption') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span @click="handleChangeAuthenStateEqualNotEqual" class="input-group-addon" role="button">
              <i class="fa filters-eq-not-eq" v-if="authenStateEqualNotEqual === '$eq'">=</i>
              <i class="filters-eq-not-eq" v-if="authenStateEqualNotEqual === '$ne'">&#8800;</i>
            </span>
            <select
              @change="selectInFilters"
              id="filter-autheN-state"
              class="form-control form-control-sm limit-select-height"
              v-model="q.AuthenState"
            >
              <option
                v-for="state of AUTHN_STATESES"
                :key="state"
                :value="state === 'unset' ? '' : state"
                :selected="state === 'unset'"
              >
                {{ $t(`portalSessions.authenState${state}`) }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="mb-h col-lg-2">
        <div class="input-group flex-column">
          <label for="filter-AuthN-type" class="mb-0 text-nowrap">
            <small>{{ $t('portalSessions.authenTypeTableCaption') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span @click="handleChangeAuthenTypeEqualNotEqual" class="input-group-addon" role="button">
              <i class="fa filters-eq-not-eq" v-if="authenTypeEqualNotEqual === '$eq'">=</i>
              <i class="filters-eq-not-eq" v-if="authenTypeEqualNotEqual === '$ne'">&#8800;</i>
            </span>
            <select
              @change="selectInFilters"
              id="filter-AuthN-type"
              class="form-control form-control-sm limit-select-height"
              v-model="q.authenticationType"
            >
              <option
                v-for="type of AUTHN_TYPES"
                :key="type"
                :value="type === 'unset' ? '' : type"
                :selected="type === 'unset'"
              >
                {{ $t(`portalSessions.authenType${type}`) }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!--      <div v-if="visits" class="mb-h col-lg-2">-->
      <!--        <div class="input-group flex-column">-->
      <!--          <label for="visits-all" class="mb-0 text-nowrap">-->
      <!--            <small>{{ $t('general.filtersVisitsAll') }}</small>-->
      <!--          </label>-->
      <!--          <div class="input-group input-group-sm">-->
      <!--            <span class="input-group-addon" role="button" @click="handleChangeVisitsGreaterLessOperator">-->
      <!--              <i class="fa" v-tooltip.auto="{ content: $t(`misc.${visitsGreaterLessOperator}`) }">-->
      <!--                {{ visitsGreaterLessOperator === '$gt' ? '>' : '' }}-->
      <!--                {{ visitsGreaterLessOperator === '$lt' ? '<' : '' }}-->
      <!--                {{ visitsGreaterLessOperator === '$eq' ? '=' : '' }}-->
      <!--              </i>-->
      <!--            </span>-->

      <!--            <input-->
      <!--              id="visits-all"-->
      <!--              name="visits-all"-->
      <!--              type="text"-->
      <!--              class="form-control"-->
      <!--              placeholder="4"-->
      <!--              v-mask="'#########'"-->
      <!--              v-model.number="q['visits.all'][visitsGreaterLessOperator]"-->
      <!--            />-->
      <!--            &lt;!&ndash; <span class="clear-input text-muted">-->
      <!--                  <i class="fa fa-remove" @click="filters.q['visits.all'] = {}"></i>-->
      <!--                </span> &ndash;&gt;-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="mb-h col-lg-2">
        <div class="input-group flex-column">
          <label for="filter-authZ-state" class="mb-0 text-nowrap">
            <small>{{ $t('portalSessions.authZStateTableCaption') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span @click="handleChangeAuthZStateEqualNotEqual" class="input-group-addon" role="button">
              <i class="fa filters-eq-not-eq" v-if="autheZStateEqualNotEqual === '$eq'">=</i>
              <i class="filters-eq-not-eq" v-if="autheZStateEqualNotEqual === '$ne'">&#8800;</i>
            </span>
            <select
              @change="selectInFilters"
              id="filter-authZ-state"
              class="form-control form-control-sm limit-select-height"
              v-model="q.authorizationState"
            >
              <option
                v-for="state of AUTHZ_STATES"
                :key="state"
                :value="state === 'unset' ? '' : state"
                :selected="state === 'unset'"
              >
                {{ $t(`portalSessions.authZState${state}`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="mb-h col-lg-2">
        <div class="input-group flex-column">
          <label for="filter-authZ-type" class="mb-0 text-nowrap">
            <small>{{ $t('portalSessions.authZTypeTableCaption') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span @click="handleChangeAuthZTypeEqualNotEqual" class="input-group-addon" role="button">
              <i class="fa filters-eq-not-eq" v-if="autheZTypeEqualNotEqual === '$eq'">=</i>
              <i class="filters-eq-not-eq" v-if="autheZTypeEqualNotEqual === '$ne'">&#8800;</i>
            </span>
            <select
              @change="selectInFilters"
              id="filter-authZ-type"
              class="form-control form-control-sm limit-select-height"
              v-model="q.authorizationType"
            >
              <option
                v-for="type of AUTHZ_TYPES"
                :key="type"
                :value="type === 'notSelect' ? '' : type"
                :selected="type === 'notSelect'"
              >
                {{ $t(`portalSessions.authZType${type}`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="mb-h col-lg-2">
        <div class="input-group flex-column">
          <label for="filter-isDeleted-type" class="mb-0 text-nowrap">
            <small>{{ $t('portalSessions.sessionIsDelitedTitleCaption') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i class="fa">=</i>
            </span>
            <select
              @change="selectInFilters"
              id="filter-isDeleted-type"
              class="form-control form-control-sm limit-select-height"
              v-model="q.is_deleted"
            >
              <option
                v-for="type of SESSION_IS_DELETED"
                :key="type"
                :value="type === 'unset' ? '' : type"
                :selected="type === 'unset'"
              >
                {{ $t(`portalSessions.sessionIsDelited${type}`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <!--    <div v-if="true" class="row flex-row justify-content-between">-->

    <!--      <div  class="mb-h col-lg-4" >-->
    <!--        <div class="input-group flex-column">-->
    <!--          <label for="filter-social" class="mb-0 text-nowrap">-->
    <!--            <small>{{ $t('portalSessions.authZStateTableCaption') }}</small>-->
    <!--          </label>-->
    <!--          <div class="input-group input-group-sm">-->
    <!--            <span @click="handleChangeAuthZStateEqualNotEqual" class="input-group-addon" role="button">-->
    <!--              <i class="fa" v-if="autheZStateEqualNotEqual === '$eq'" > = </i>-->
    <!--              <i class="fa" v-if="autheZStateEqualNotEqual === '$ne'"> != </i>-->
    <!--            </span>-->
    <!--            <select-->
    <!--              id="filter-authZ-state"-->
    <!--              class="form-control form-control-sm limit-select-height"-->
    <!--              v-model="q.authorizationState"-->
    <!--            >-->
    <!--              <option-->
    <!--                v-for="state of AUTHZ_STATES"-->
    <!--                :key="state"-->
    <!--                :value="state === 'unset' ? '' : state"-->
    <!--                :selected="state === 'unset'"-->
    <!--              >-->
    <!--                {{ $t(`portalSessions.authZState${state}`) }}-->
    <!--              </option>-->
    <!--            </select>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--            <div  class="mb-h col-lg-4" >-->
    <!--        <div class="input-group flex-column">-->
    <!--          <label for="filter-social" class="mb-0 text-nowrap">-->
    <!--            <small>{{ $t('portalSessions.authZTypeTableCaption') }}</small>-->
    <!--          </label>-->
    <!--          <div class="input-group input-group-sm">-->
    <!--            <span @click="handleChangeAuthZTypeEqualNotEqual" class="input-group-addon" role="button">-->
    <!--              <i class="fa" v-if="autheZTypeEqualNotEqual === '$eq'" > = </i>-->
    <!--              <i class="fa" v-if="autheZTypeEqualNotEqual === '$ne'"> != </i>-->
    <!--            </span>-->
    <!--            <select-->
    <!--              id="filter-authZ-type"-->
    <!--              class="form-control form-control-sm limit-select-height"-->
    <!--              v-model="q.authorizationType"-->
    <!--            >-->
    <!--              <option-->
    <!--                v-for="type of AUTHZ_TYPES"-->
    <!--                :key="type"-->
    <!--                :value="type === 'notSelect' ? '' : type"-->
    <!--                :selected="type === 'notSelect'"-->
    <!--              >-->
    <!--                {{ $t(`portalSessions.authZType${type}`) }}-->
    <!--              </option>-->
    <!--            </select>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--                  <div  class="mb-h col-lg-4" >-->
    <!--        <div class="input-group flex-column">-->
    <!--          <label for="filter-social" class="mb-0 text-nowrap">-->
    <!--            <small>{{ $t('portalSessions.sessionIsDelitedTitleCaption') }}</small>-->
    <!--          </label>-->
    <!--          <div class="input-group input-group-sm">-->
    <!--            <span @click="handleChangeAuthZTypeEqualNotEqual" class="input-group-addon" role="button">-->
    <!--              <i class="fa"> = </i>-->
    <!--            </span>-->
    <!--            <select-->
    <!--              id="filter-isDeleted-type"-->
    <!--              class="form-control form-control-sm limit-select-height"-->
    <!--              v-model="q.is_deleted"-->
    <!--            >-->
    <!--              <option-->
    <!--                v-for="type of SESSION_IS_DELETED"-->
    <!--                :key="type"-->
    <!--                :value="type === 'unset' ? '' : type"-->
    <!--                :selected="type === 'unset'"-->
    <!--              >-->
    <!--                {{ $t(`portalSessions.sessionIsDelited${type}`) }}-->
    <!--              </option>-->
    <!--            </select>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--&lt;!&ndash;            <div v-if="social" class="mb-h col-lg-4" >&ndash;&gt;-->
    <!--&lt;!&ndash;        <div class="input-group flex-column">&ndash;&gt;-->
    <!--&lt;!&ndash;          <label for="filter-social" class="mb-0 text-nowrap">&ndash;&gt;-->
    <!--&lt;!&ndash;            <small>{{ $t('general.filtersSocialNetwork') }}</small>&ndash;&gt;-->
    <!--&lt;!&ndash;          </label>&ndash;&gt;-->
    <!--&lt;!&ndash;          <div class="input-group input-group-sm">&ndash;&gt;-->
    <!--&lt;!&ndash;            <span class="input-group-addon">&ndash;&gt;-->
    <!--&lt;!&ndash;              <i class="fa fa-user" />&ndash;&gt;-->
    <!--&lt;!&ndash;            </span>&ndash;&gt;-->
    <!--&lt;!&ndash;            <select&ndash;&gt;-->
    <!--&lt;!&ndash;              id="filter-social"&ndash;&gt;-->
    <!--&lt;!&ndash;              class="form-control form-control-sm limit-select-height"&ndash;&gt;-->
    <!--&lt;!&ndash;              v-model="q.social_network"&ndash;&gt;-->
    <!--&lt;!&ndash;            >&ndash;&gt;-->
    <!--&lt;!&ndash;              <option&ndash;&gt;-->
    <!--&lt;!&ndash;                v-for="social of SOCIALS"&ndash;&gt;-->
    <!--&lt;!&ndash;                :key="social"&ndash;&gt;-->
    <!--&lt;!&ndash;                :value="social === 'unset' ? '' : social"&ndash;&gt;-->
    <!--&lt;!&ndash;                :selected="social === 'unset'"&ndash;&gt;-->
    <!--&lt;!&ndash;              >&ndash;&gt;-->
    <!--&lt;!&ndash;                {{ $t(`portalStats.auth.${social}`) }}&ndash;&gt;-->
    <!--&lt;!&ndash;              </option>&ndash;&gt;-->
    <!--&lt;!&ndash;            </select>&ndash;&gt;-->
    <!--&lt;!&ndash;          </div>&ndash;&gt;-->
    <!--&lt;!&ndash;        </div>&ndash;&gt;-->
    <!--&lt;!&ndash;      </div>&ndash;&gt;-->

    <!--    </div>-->
    <!--    <div v-if="true" class="row flex-row justify-content-between">-->

    <!--            <div  class="mb-h col-lg-4" >-->
    <!--        <div class="input-group flex-column">-->
    <!--          <label for="filter-social" class="mb-0 text-nowrap">-->
    <!--            <small>{{ $t('portalSessions.sessionIsDelitedTitleCaption') }}</small>-->
    <!--          </label>-->
    <!--          <div class="input-group input-group-sm">-->
    <!--            <span @click="handleChangeAuthZTypeEqualNotEqual" class="input-group-addon" role="button">-->
    <!--              <i class="fa"> = </i>-->
    <!--            </span>-->
    <!--            <select-->
    <!--              id="filter-isDeleted-type"-->
    <!--              class="form-control form-control-sm limit-select-height"-->
    <!--              v-model="q.is_deleted"-->
    <!--            >-->
    <!--              <option-->
    <!--                v-for="type of SESSION_IS_DELETED"-->
    <!--                :key="type"-->
    <!--                :value="type === 'unset' ? '' : type"-->
    <!--                :selected="type === 'unset'"-->
    <!--              >-->
    <!--                {{ $t(`portalSessions.sessionIsDelited${type}`) }}-->
    <!--              </option>-->
    <!--            </select>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--    </div>-->
    <div
      class="row flex-row justify-content-between align-items-start"
      :class="{ 'mb-1': !showingDatesFiltersBlock, 'mb-q': showingDatesFiltersBlock }"
    >
      <div
        class="col-lg-6 d-flex justify-content-start align-items-start"
        :class="{ 'mt-1': !showingDatesFiltersBlock, 'mt-q': showingDatesFiltersBlock }"
      >
        <!--        <div><span>{{$t('portalSessions.dateTimeFiltersCaption')}}</span></div>-->
        <div role="button" @click="toggleDatesFiltersBlock">
          <i v-if="showingDatesFiltersBlock" class="icon-arrow-down"></i>
          <i v-else class="icon-arrow-right"></i>
          {{ $t('portalSessions.dateTimeFiltersCaption') }}
          <transition name="datesBlockInPortalSessionsFilters">
            <small class="ml-q" v-if="oneOrMoreDateTimeFiltersAreEnabled">
              ({{ $t('portalSessions.oneOrMoreDateTimeFiltersAreEnabled') }})
            </small>
          </transition>
        </div>
      </div>
      <!--      <transition name="datesBlockInPortalSessionsFilters">-->
      <!--        <div v-if="oneOrMoreFilterChanged && !showingDatesFiltersBlock" class="mb-h d-flex justify-content-end"-->
      <!--             :class="{ 'col-lg-3 col-sm': fourthRow < 4, 'col-lg-2 col-sm-3': fourthRow === 4 }">-->
      <!--          <button class="btn-outline-secondary btn btn-sm btn-block" @click.prevent="resetFilters">-->
      <!--            <i class="fa fa-close"></i>-->
      <!--            {{ $t('general.resetAllFilters') }}-->
      <!--          </button>-->
      <!--        </div>-->
      <!--      </transition>-->
      <transition name="datesBlockInPortalSessionsFilters">
        <div
          v-show="!showingDatesFiltersBlock"
          class="mb-h apply-button-when-dates-filter-block-not-shown"
          :class="{ 'col-lg-3': !oneOrMoreFilterChanged, 'col-lg-6': oneOrMoreFilterChanged }"
        >
          <br />
<!--          <div class="d-flex">-->
<!--            <button-->
<!--              v-if="oneOrMoreFilterChanged"-->
<!--              class="btn btn-outline-secondary btn btn-sm reset-button mb-h w-100 new-modern-style-btn-in-filters"-->
<!--              @click.prevent="resetFilters"-->
<!--            >-->
<!--              <i class="fa fa-close"></i>-->
<!--              {{ $t('general.resetAllFilters') }}-->
<!--            </button>-->

<!--&lt;!&ndash;            <button&ndash;&gt;-->
<!--&lt;!&ndash;              class="btn btn-primary btn-sm mb-h w-100"&ndash;&gt;-->
<!--&lt;!&ndash;              type="submit"&ndash;&gt;-->
<!--&lt;!&ndash;              :class="{ 'ml-1': oneOrMoreFilterChanged, 'ml-0': !oneOrMoreFilterChanged }"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              {{ $t('general.applyFilters') }}&ndash;&gt;-->
<!--&lt;!&ndash;            </button>&ndash;&gt;-->
<!--          </div>-->
        </div>
      </transition>
    </div>
    <transition name="datesBlockInPortalSessionsFilters">
      <div v-if="showingDatesFiltersBlock" class="row flex-row justify-content-start">
        <!--      <div class="mb-h col-lg-3 d-flex justify-content-start align-items-end">-->
        <!--        <div class="flex-column datefilters-wrapper-in-portal-sessions-filters">-->
        <!--          <label for="filter-social" class="mb-0 text-nowrap ">-->
        <!--&lt;!&ndash;            <small>вкл</small>&ndash;&gt;-->
        <!--&lt;!&ndash;            <small class="small-placehilder-in-portal-sessions-filters"></small>&ndash;&gt;-->
        <!--          </label>-->
        <!--          <div class="d-flex align-items-end justify-content-right">-->
        <!--            <Switch-component-->
        <!--              v-model="filterSettings.enablingDateTimeFilters.create_at"-->
        <!--              class='switcher-conn-disconn-date-range-filter-in-portal-sessions-filters'-->
        <!--            />-->
        <!--          </div>-->
        <!--        </div>-->
        <!--          <div class=" ml-h">-->
        <!--                              <div class="input-group input-group-sm filters__location ">-->
        <!--                    <small>{{$t('portalSessions.creationSessionForTable')}}</small>-->
        <!--                  </div>-->
        <!--            <div class="btn-group btn-group-sm   datepicker-in-portal-sessions-filters"-->
        <!--                 data-toggle="button">-->
        <!--              <date-picker-->
        <!--                v-model="q.create_at"-->
        <!--                class="float-right"-->
        <!--                :disabled='!filterSettings.enablingDateTimeFilters.create_at'-->

        <!--              />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--      </div>-->
        <div class="mb-h col-lg-3 d-flex justify-content-start align-items-end">
          <div class="flex-column datefilters-wrapper-in-portal-sessions-filters">
            <label for="create_at-switch" class="mb-0 text-nowrap">
              <small>{{ $t('portalSessions.creationSessionForTable') }}</small>
              <!--            <small class="small-placehilder-in-portal-sessions-filters"></small>-->
            </label>
            <div class="d-flex align-items-end justify-content-right w-100">
              <Switch-component
                @change="selectInFilters"
                :id="'create_at-switch'"
                v-model="filterSettings.enablingDateTimeFilters.create_at"
                class="switcher-conn-disconn-date-range-filter-in-portal-sessions-filters"
              />
              <div
                v-if="showDateFilters"
                class="btn-group btn-group-sm datepicker-in-portal-sessions-filters ml-h w-100"
              >
                <date-picker
                  @input="selectInFilters"
                  v-model="q.create_at"
                  class="float-right w-100"
                  :disabled="!filterSettings.enablingDateTimeFilters.create_at"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mb-h col-lg-3 d-flex justify-content-start align-items-end">
          <div class="flex-column datefilters-wrapper-in-portal-sessions-filters">
            <label for="expire_at-switch" class="mb-0 text-nowrap">
              <small>{{ $t('portalSessions.expirationSessionForTable') }}</small>
              <!--            <small class="small-placehilder-in-portal-sessions-filters"></small>-->
            </label>
            <div class="d-flex align-items-end justify-content-between w-100">
              <Switch-component
                @change="selectInFilters"
                :id="'expire_at-switch'"
                v-model="filterSettings.enablingDateTimeFilters.expire_at"
                class="switcher-conn-disconn-date-range-filter-in-portal-sessions-filters"
              />
              <div
                v-if="showDateFilters"
                class="btn-group btn-group-sm datepicker-in-portal-sessions-filters ml-h w-100"
              >
                <date-picker
                  @input="selectInFilters"
                  v-model="q.expire_at"
                  class="float-right w-100"
                  :disabled="!filterSettings.enablingDateTimeFilters.expire_at"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mb-h col-lg-3 d-flex justify-content-start align-items-end">
          <div class="flex-column datefilters-wrapper-in-portal-sessions-filters">
            <label for="start_at-switch" class="mb-0 text-nowrap">
              <small>{{ $t('portalSessions.authZTimeBeginTableCaption') }}</small>
              <!--            <small class="small-placehilder-in-portal-sessions-filters"></small>-->
            </label>
            <div class="d-flex align-items-end justify-content-between w-100">
              <Switch-component
                @change="selectInFilters"
                :id="'start_at-switch'"
                v-model="filterSettings.enablingDateTimeFilters.start_at"
                class="switcher-conn-disconn-date-range-filter-in-portal-sessions-filters"
              />
              <div
                v-if="showDateFilters"
                class="btn-group btn-group-sm datepicker-in-portal-sessions-filters ml-h w-100"
              >
                <date-picker
                  @input="selectInFilters"
                  v-model="q.start_at"
                  class="float-right w-100"
                  :disabled="!filterSettings.enablingDateTimeFilters.start_at"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mb-h col-lg-3 d-flex justify-content-start align-items-end">
          <div class="flex-column datefilters-wrapper-in-portal-sessions-filters">
            <label for="stop_at-switch" class="mb-0 text-nowrap">
              <small>{{ $t('portalSessions.authZTimeEndTableCaption') }}</small>
              <!--            <small class="small-placehilder-in-portal-sessions-filters"></small>-->
            </label>
            <div class="d-flex align-items-end justify-content-between w-100">
              <Switch-component
                @change="selectInFilters"
                :id="'stop_at-switch'"
                v-model="filterSettings.enablingDateTimeFilters.stop_at"
                class="switcher-conn-disconn-date-range-filter-in-portal-sessions-filters"
              />
              <div
                v-if="showDateFilters"
                class="btn-group btn-group-sm datepicker-in-portal-sessions-filters ml-h w-100"
              >
                <date-picker
                  @input="selectInFilters"
                  v-model="q.stop_at"
                  class="float-right w-100"
                  :disabled="!filterSettings.enablingDateTimeFilters.stop_at"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
<!--    <transition name="datesBlockInPortalSessionsFilters">-->
<!--      <div v-if="showingDatesFiltersBlock" class="row flex-row-reverse justify-content-start align-items-start mt-q">-->
<!--        <div-->
<!--          v-if="true"-->
<!--          class="mb-h"-->
<!--          :class="{ 'col-lg-3 col-sm': fourthRow < 4, 'col-lg-2 col-sm-3': fourthRow === 4 }"-->
<!--        >-->
<!--          <button class="btn btn-primary btn-sm btn-block" type="submit">-->
<!--            {{ $t('general.applyFilters') }}-->
<!--          </button>-->
<!--        </div>-->
<!--        <transition name="datesBlockInPortalSessionsFilters">-->
<!--          <div-->
<!--            v-if="oneOrMoreFilterChanged"-->
<!--            class="mb-h d-flex justify-content-end"-->
<!--            :class="{ 'col-lg-3 col-sm': fourthRow < 4, 'col-lg-2 col-sm-3': fourthRow === 4 }"-->
<!--          >-->
<!--            <button class="btn-outline-secondary btn btn-sm btn-block" @click.prevent="resetFilters">-->
<!--              <i class="fa fa-close"></i>-->
<!--              {{ $t('general.resetAllFilters') }}-->
<!--            </button>-->
<!--          </div>-->
<!--        </transition>-->
<!--      </div>-->
<!--    </transition>-->
  </form>
</template>

<script>
import { mask } from 'vue-the-mask';

import DatePicker from './date-picker.vue';
import MultiSelectComponent from './Universal/select/multi-select-component.vue';
import SelectComponent from './Universal/select/select-component.vue';
import CheckboxComponent from './Universal/Checkbox-component.vue';
import SwitchComponent from './Universal/Switch-component.vue';
import helpers from '../helpers';
import portalProfilesService from '../services/portalProfilesService';
import locationService from '../services/locationService';
import SelectComponentV2 from './Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from './Universal/select/select-componentV3ForUseInLocationSelectors.vue';

const GENDERS = ['male', 'female', 'unset'];
const SOCIALS = ['unset', 'vk', 'facebook', 'instagram'];
const SESSION_STATES = ['unset', 'authenticate', 'authorize', 'advertise', 'pass'];
const AUTHN_STATESES = ['unset', 'need', 'checked', 'sent'];
const AUTHN_TYPES = ['unset', 'sms', 'email', 'esia', 'userpass', 'callback', 'callfront'];
const AUTHZ_STATES = ['unset', 'none', 'checked'];
const AUTHZ_TYPES = [
  'notSelect',
  'free',
  'sponsor',
  'vk',
  'voucher',
  'facebook',
  'instagram',
  'hotel_voucher',
  'skip',
  'staff',
  'subscription'
];
const SESSION_IS_DELETED = ['unset', false, true];

export default {
  name: 'TablePaginationForPortalSessionsV3',
  components: {
    MultiSelectComponent,
    SelectComponent,
    CheckboxComponent,
    DatePicker,
    SelectComponentV2,
    SwitchComponent,
    SelectComponentV3ForUseInLocationSelectors

  },
  props: {
    value: {
      search: { type: String, required: false },
      limit: { type: String, required: false },
      offset: { type: String, required: false },
      sort_by: { type: String, required: false },
      sort_order: { type: String, required: false },
      profiles: { type: Array, required: false },
      location: {
        data: { type: Object, required: false },
        with_childs: { type: Boolean, required: false }
      },
      q: { type: Object, required: false }
    },
    onSubmit: { type: Function, required: true },
    options: { type: Array, required: true },
    showFilters: { type: Array, required: true },
    showSlotForProfileSelctor: {
      type: Boolean,
      required: false,
      default: false
    },
    // showSearchField: { type: Boolean, default: true },
    // Плохо, но по-другому не получается :(
    q: { type: [Object, Boolean], default: false },
    filterSettings: { type: [Object, Boolean], default: false }
  },
  directives: { mask },
  watch: {
    filters: {
      handler() {
        this.updateData(this);
      },
      deep: true
    }
  },
  data() {
    return {
      smallHeightForCustomMultiselectAndSelectComponents: true,
      showLocationFilter: true,
      locationInFiltersData: [],
      showingDatesFiltersBlock: false,
      visitsGreaterLessOperator: '$gt',
      sessionStateEqualNotEqual: '$eq',
      authenStateEqualNotEqual: '$eq',
      authenTypeEqualNotEqual: '$eq',
      autheZStateEqualNotEqual: '$eq',
      autheZTypeEqualNotEqual: '$eq',

      // тут запоминаем значения, которые были при отрисовкt компонента для фильтров
      // поиск, сортировка, лимит, количество записей, профиль, локация
      // поиск
      searchBeforeChanging: this.value.search,
      // сортировка
      sortOrderBeforeChanging: this.value.sort_order,
      sortByBeforeChanging: this.value.sort_by,
      // лимит
      limitBeforeChanging: this.value.limit,
      // профиль
      profilesBeforeChanging: JSON.parse(JSON.stringify(this.value.profiles)),
      showProfilesFilter: true, // для перерисовки при сбросе фильтров
      // локация
      locationsBeforeChanging: JSON.parse(JSON.stringify(this.value.location.data)),
      locationsWithChildsBeforeChanging: this.value.location.with_childs,
      showLocationsFilter: true, // для перерисовки при сбросе фильтров

      // тут запоминаем значения, которые были при отрисовке компонента для фильтров по сессии
      // Статус сессии
      stateBeforeChanging: this.q.state,
      sessionStateEqualNotEqualBeforeChanging: this.filterSettings.state,
      // Статус идентификации
      AuthenStateBeforeChanging: this.q.AuthenState,
      authenStateEqualNotEqualBeforeChanging: this.filterSettings.AuthenState,
      // Тип идентификации
      authenticationTypeBeforeChanging: this.q.authenticationType,
      authenTypeEqualNotEqualBeforeChanging: this.filterSettings.authenType,
      // Статус авторизации
      authorizationStateBeforeChanging: this.q.authorizationState,
      autheZStateEqualNotEqualBeforeChanging: this.filterSettings.authZState,
      // Тип авторизации
      authorizationTypeBeforeChanging: this.q.authorizationType,
      autheZTypeEqualNotEqualBeforeChanging: this.filterSettings.authZType,
      // Сессия удалена
      is_deletedBeforeChanging: this.q.is_deleted,

      // тут запоминаем значения, которые были при отрисовке компонента для фильтров по дате времени
      // чтобы потом восстановить эти значения при сбросе фильтров
      create_atStartDateBeforeChanging: this.q.create_at.startDate,
      create_atEndDateBeforeChanging: this.q.create_at.endDate,
      expire_atStartDateBeforeChanging: this.q.expire_at.startDate,
      expire_atEndDateBeforeChanging: this.q.expire_at.endDate,
      start_atStartDateBeforeChanging: this.q.start_at.startDate,
      start_atEndDateBeforeChanging: this.q.start_at.endDate,
      stop_atStartDateBeforeChanging: this.q.stop_at.startDate,
      stop_atEndDateBeforeChanging: this.q.stop_at.endDate,
      showDateFilters: true // для перерисовки при сбросе фильтров
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    oneOrMoreFilterChanged() {
      // возвращаем true если хотя бы один из фильтров изменен\включен
      try {
        if (
          this.oneOrMoreDateTimeFiltersAreChanged ||
          this.oneOrMoreDateTimeFiltersAreEnabled ||
          this.searchIsChanged ||
          this.sortIsChanged ||
          this.limitIsChanged ||
          this.profilesIsChanged ||
          this.locationsIsChanged ||
          this.stateIsChanged ||
          this.authenStateIsChanged ||
          this.authenticationTypeIsChanged ||
          this.authorizationStateIsChanged ||
          this.authorizationTypeIsChanged ||
          this.is_deletedIsChanged
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },

    is_deletedIsChanged() {
      try {
        if (this.is_deletedBeforeChanging !== this.q.is_deleted) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },

    authorizationTypeIsChanged() {
      try {
        if (
          this.authorizationTypeBeforeChanging !== this.q.authorizationType ||
          this.autheZTypeEqualNotEqualBeforeChanging !== this.filterSettings.authZType
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },

    authorizationStateIsChanged() {
      try {
        if (
          this.authorizationStateBeforeChanging !== this.q.authorizationState ||
          this.autheZStateEqualNotEqualBeforeChanging !== this.filterSettings.authZState
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },

    authenticationTypeIsChanged() {
      try {
        if (
          this.authenticationTypeBeforeChanging !== this.q.authenticationType ||
          this.authenTypeEqualNotEqualBeforeChanging !== this.filterSettings.authenType
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    authenStateIsChanged() {
      try {
        if (
          this.AuthenStateBeforeChanging !== this.q.AuthenState ||
          this.authenStateEqualNotEqualBeforeChanging !== this.filterSettings.AuthenState
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    stateIsChanged() {
      try {
        if (
          this.stateBeforeChanging !== this.q.state ||
          this.sessionStateEqualNotEqualBeforeChanging !== this.filterSettings.state
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    locationsIsChanged() {
      try {
        if (
          Object.keys(this.locationsBeforeChanging).length !== Object.keys(this.value.location.data).length ||
          this.locationsWithChildsBeforeChanging !== this.value.location.with_childs
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    profilesIsChanged() {
      try {
        if (this.profilesBeforeChanging.length !== this.value.profiles.length) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    limitIsChanged() {
      try {
        if (this.limitBeforeChanging !== this.value.limit) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    sortIsChanged() {
      try {
        if (
          this.sortOrderBeforeChanging !== this.value.sort_order ||
          this.sortByBeforeChanging !== this.value.sort_by
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    searchIsChanged() {
      try {
        if (this.searchBeforeChanging !== this.value.search) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    oneOrMoreDateTimeFiltersAreChanged() {
      // возврщаем true если выбраные значения хотя бы в одном фильтре по дате-времени изменились
      try {
        if (
          this.create_atStartDateBeforeChanging !== this.q.create_at.startDate ||
          this.create_atEndDateBeforeChanging !== this.q.create_at.endDate ||
          this.expire_atStartDateBeforeChanging !== this.q.expire_at.startDate ||
          this.expire_atEndDateBeforeChanging !== this.q.expire_at.endDate ||
          this.start_atStartDateBeforeChanging !== this.q.start_at.startDate ||
          this.start_atEndDateBeforeChanging !== this.q.start_at.endDate ||
          this.stop_atStartDateBeforeChanging !== this.q.stop_at.startDate ||
          this.stop_atEndDateBeforeChanging !== this.q.stop_at.endDate
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    oneOrMoreDateTimeFiltersAreEnabled() {
      // возвращаем true если один или несколько фильтров по дате-времени включены
      try {
        if (
          this.filterSettings.enablingDateTimeFilters.create_at === true ||
          this.filterSettings.enablingDateTimeFilters.expire_at === true ||
          this.filterSettings.enablingDateTimeFilters.start_at === true ||
          this.filterSettings.enablingDateTimeFilters.stop_at === true
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    firstRow() {
      return [this.search, this.sort, this.limit, this.offset].filter(Boolean).length;
    },
    secondRow() {
      return [this.profile, this.location].filter(Boolean).length;
    },
    thirdRow() {
      return [this.city, this.homeTown, this.birthday, this.sex].filter(Boolean).length;
    },
    fourthRow() {
      return [this.social, this.visits, true].filter(Boolean).length;
    },
    profile() {
      return this.showFilters.some((filter) => filter === 'profile');
    },
    search() {
      return this.showFilters.some((filter) => filter === 'search');
    },
    sex() {
      return this.showFilters.some((filter) => filter === 'sex');
    },
    location() {
      return this.showFilters.some((filter) => filter === 'location');
    },
    birthday() {
      return this.showFilters.some((filter) => filter === 'birthday');
    },
    social() {
      return this.showFilters.some((filter) => filter === 'social');
    },
    date() {
      return this.showFilters.some((filter) => filter === 'date');
    },
    visits() {
      return this.showFilters.some((filter) => filter === 'visits');
    },
    sort() {
      return this.showFilters.some((filter) => filter === 'sort');
    },
    limit() {
      return this.showFilters.some((filter) => filter === 'limit');
    },
    city() {
      return this.showFilters.some((filter) => filter === 'city');
    },
    homeTown() {
      return this.showFilters.some((filter) => filter === 'home_town');
    },
    offset() {
      return this.showFilters.some((filter) => filter === 'offset');
    },
    isDisabledWithChilds() {
      if (this.value.location.data.id === '') {
        return true;
      }
      return false;
    },
    GENDERS() {
      return GENDERS;
    },
    SOCIALS() {
      return SOCIALS;
    },
    SESSION_STATES() {
      return SESSION_STATES;
    },
    AUTHN_STATESES() {
      return AUTHN_STATESES;
    },
    AUTHN_TYPES() {
      return AUTHN_TYPES;
    },
    AUTHZ_STATES() {
      return AUTHZ_STATES;
    },
    AUTHZ_TYPES() {
      return AUTHZ_TYPES;
    },
    SESSION_IS_DELETED() {
      return SESSION_IS_DELETED;
    }
  },
  methods: {
    selectLocation(location) {
      if (!location) {
        this.value.location.data = {};
      }
    },
    resetFilters() {
      // console.log('filters reseted');
      this.resetSerachFilter();
      this.resetSortFilter();
      this.resetLimitFilter();
      this.resetProfilesFilter();
      this.resetLocationsFilter();

      this.resetStateFilter();
      this.resetAuthenStateFilter();
      this.resetAuthenticationTypeFilter();
      this.resetAuthorizationStateFilter();
      this.resetAuthorizationTypeFilter();
      this.resetIs_deletedFilter();

      this.resetDateTimeFiltersEnabling();
      this.resetDateTimeFiltersValues();
    },

    searchClear() {
      this.$refs.search.value = '';
      // this.updateData(this);
      this.selectInFilters();
    },

    selectLocationInFilters(location) {
      this.selectInFilters();
    },

    selectInFilters(props) {
      this.updateData(props);
      this.onSubmit();
    },

    selectInFiltersWithDelay(props) {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }
      this.delayTimer = setTimeout(() => {
        this.updateData(props);
        this.onSubmit();
      }, 1000)
    },

    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },

    resetIs_deletedFilter() {
      // сбрасываем фильтр по Сессия удалена
      this.q.is_deleted = this.is_deletedBeforeChanging;
    },

    resetAuthorizationTypeFilter() {
      // сбрасываем фильтр по Типу авторизации
      this.q.authorizationType = this.authorizationTypeBeforeChanging;
      this.filterSettings.authZType = this.autheZTypeEqualNotEqualBeforeChanging;
      this.autheZTypeEqualNotEqual = this.autheZTypeEqualNotEqualBeforeChanging;
    },

    resetAuthorizationStateFilter() {
      // сбрасываем фильтр по Статусу авторизации
      this.q.authorizationState = this.authorizationStateBeforeChanging;
      this.filterSettings.authZState = this.autheZStateEqualNotEqualBeforeChanging;
      this.autheZStateEqualNotEqual = this.autheZStateEqualNotEqualBeforeChanging;
    },

    resetAuthenticationTypeFilter() {
      // сбрасываем фильтр по Типу идентификации
      this.q.authenticationType = this.authenticationTypeBeforeChanging;
      this.filterSettings.authenType = this.authenTypeEqualNotEqualBeforeChanging;
      this.authenTypeEqualNotEqual = this.authenTypeEqualNotEqualBeforeChanging;
    },
    resetAuthenStateFilter() {
      // сбрасываем фильтр по Статусу идентификации
      this.q.AuthenState = this.AuthenStateBeforeChanging;
      this.filterSettings.AuthenState = this.authenStateEqualNotEqualBeforeChanging;
      this.authenStateEqualNotEqual = this.authenStateEqualNotEqualBeforeChanging;
    },
    resetStateFilter() {
      // сбрасываем фильтр по Статусу сессии
      this.q.state = this.stateBeforeChanging;
      this.filterSettings.state = this.sessionStateEqualNotEqualBeforeChanging;
      this.sessionStateEqualNotEqual = this.sessionStateEqualNotEqualBeforeChanging;
    },
    resetLocationsFilter() {
      // сбрасываем филтр по локациям и перерисовывваем его
      this.value.location.data = JSON.parse(JSON.stringify(this.locationsBeforeChanging));
      this.value.location.with_childs = this.locationsWithChildsBeforeChanging;
      this.showLocationsFilter = false;
      this.$nextTick(() => {
        this.showLocationsFilter = true;
      });
    },
    resetProfilesFilter() {
      // сбрасываем филтр по профилям и перерисовывваем его
      this.value.profiles = JSON.parse(JSON.stringify(this.profilesBeforeChanging));
      this.showProfilesFilter = false;
      this.$nextTick(() => {
        this.showProfilesFilter = true;
      });
    },
    resetLimitFilter() {
      // сбрасываем фильтр по search
      this.value.limit = this.limitBeforeChanging;
    },
    resetSortFilter() {
      // сбрасываем фильтр по sort
      this.value.sort_order = this.sortOrderBeforeChanging;
      this.value.sort_by = this.sortByBeforeChanging;
    },
    resetSerachFilter() {
      // сбрасываем фильтр по search
      this.value.search = this.searchBeforeChanging;
    },
    resetDateTimeFiltersEnabling() {
      // выключем все фильтры по дате-времени
      this.filterSettings.enablingDateTimeFilters.create_at = false;
      this.filterSettings.enablingDateTimeFilters.expire_at = false;
      this.filterSettings.enablingDateTimeFilters.start_at = false;
      this.filterSettings.enablingDateTimeFilters.stop_at = false;
    },
    resetDateTimeFiltersValues() {
      // сбрасываем значения даты-времен и фильтрах дата-время
      this.q.create_at.startDate = this.create_atStartDateBeforeChanging;
      this.q.create_at.endDate = this.create_atEndDateBeforeChanging;
      this.q.expire_at.startDate = this.expire_atStartDateBeforeChanging;
      this.q.expire_at.endDate = this.expire_atEndDateBeforeChanging;
      this.q.start_at.startDate = this.start_atStartDateBeforeChanging;
      this.q.start_at.endDate = this.start_atEndDateBeforeChanging;
      this.q.stop_at.startDate = this.stop_atStartDateBeforeChanging;
      this.q.stop_at.endDate = this.stop_atEndDateBeforeChanging;

      this.showDateFilters = false;
      this.$nextTick(() => {
        this.showDateFilters = true;
      });
    },
    toggleDatesFiltersBlock() {
      this.showingDatesFiltersBlock = !this.showingDatesFiltersBlock;
    },
    handleChangeSeassionStateEqualNotEqual() {
      if (this.sessionStateEqualNotEqual === '$eq') {
        this.sessionStateEqualNotEqual = '$ne';
        this.emitFilterSettings('state', '$ne');
      } else {
        this.sessionStateEqualNotEqual = '$eq';
        this.emitFilterSettings('state', '$eq');
      }
      this.selectInFilters();
    },
    handleChangeAuthenStateEqualNotEqual() {
      if (this.authenStateEqualNotEqual === '$eq') {
        this.authenStateEqualNotEqual = '$ne';
        this.emitFilterSettings('AuthenState', '$ne');
      } else {
        this.authenStateEqualNotEqual = '$eq';
        this.emitFilterSettings('AuthenState', '$eq');
      }
      this.selectInFilters();
    },
    handleChangeAuthenTypeEqualNotEqual() {
      if (this.authenTypeEqualNotEqual === '$eq') {
        this.authenTypeEqualNotEqual = '$ne';
        this.emitFilterSettings('authenType', '$ne');
      } else {
        this.authenTypeEqualNotEqual = '$eq';
        this.emitFilterSettings('authenType', '$eq');
      }
      this.selectInFilters();
    },
    handleChangeAuthZStateEqualNotEqual() {
      if (this.autheZStateEqualNotEqual === '$eq') {
        this.autheZStateEqualNotEqual = '$ne';
        this.emitFilterSettings('authZState', '$ne');
      } else {
        this.autheZStateEqualNotEqual = '$eq';
        this.emitFilterSettings('authZState', '$eq');
      }
      this.selectInFilters();
    },
    handleChangeAuthZTypeEqualNotEqual() {
      if (this.autheZTypeEqualNotEqual === '$eq') {
        this.autheZTypeEqualNotEqual = '$ne';
        this.emitFilterSettings('authZType', '$ne');
      } else {
        this.autheZTypeEqualNotEqual = '$eq';
        this.emitFilterSettings('authZType', '$eq');
      }
      this.selectInFilters();
    },
    emitFilterSettings(filter, setting) {
      const emitedObject = {};
      emitedObject[filter] = setting;
      this.$emit('emitFilterSettings', emitedObject);
    },
    async requestProfiles(searchQuery) {
      // return portalProfilesService.requestProfiles(searchQuery);
      return portalProfilesService.requestProfilesFilteredByLocationAndWithChilds(this, searchQuery);
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    handleChangeVisitsGreaterLessOperator() {
      if (this.visitsGreaterLessOperator === '$gt') {
        this.visitsGreaterLessOperator = '$lt';
        delete this.q['visits.all'].$gt;
        delete this.q['visits.all'].$eq;
      } else if (this.visitsGreaterLessOperator === '$lt') {
        this.visitsGreaterLessOperator = '$eq';
        delete this.q['visits.all'].$lt;
        delete this.q['visits.all'].$gt;
      } else {
        this.visitsGreaterLessOperator = '$gt';
        delete this.q['visits.all'].$lt;
        delete this.q['visits.all'].$eq;
      }
    },
    toggleWithChilds() {
      // this.value.location.with_childs = !this.value.location.with_childs;
      this.$nextTick(() => {
        this.onSubmit();
      });
    },
    // searchClear() {
    //   this.$refs.search.value = '';
    //   this.updateData(this);
    // },

    flipSort(current) {
      return current === 1 ? -1 : 1;
    },
    updateData(props) {
      // console.log(props);
      const value = JSON.parse(JSON.stringify(this.value));
      const q = JSON.parse(JSON.stringify(this.q));

      this.$emit('input', {
        ...value,
        limit: helpers.validatePagination(this.$refs.limit.value, 1),
        offset: this.offset ? helpers.validatePagination(this.$refs.offset.value) : 0,
        sort_by: this.$refs.sort_by.value,
        sort_order: props?.sort_order ? this.flipSort(this.value.sort_order) : this.value.sort_order,
        search: this.$refs.search.value,
        // profiles: this.value.profiles,
        // location: this.value.location,
        // date: this.value.date,
        q: this.q ? q : {}
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.limit-select-height {
  height: 27px !important;
}

.limit-pagination-width {
  max-width: 200px;
}

.page-offset-no-display {
  display: none;
}
</style>
<style lang="scss">
  .multiselect-in-portal-pagination-for-sessions-filterss {
  min-height: 27px !important;
}

.multiselect-in-portal-pagination-for-sessions-filterss .multi-select__control {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  max-height: 300px !important;
  min-height: 27px !important;
  align-items: center;
}

.multiselect-in-portal-pagination-for-sessions-filterss .multi-select__input {
  min-height: 27px !important;
  margin-bottom: 0px !important;
}

.multiselect-in-portal-pagination-for-sessions-filterss .arrow {
  min-height: 27px !important;
}

.multiselect-in-portal-pagination-for-sessions-filterss .multi-select__tag {
  margin-bottom: 0px;
  height: 22px !important;
}
  .input-group-for-location-select-in-TablePaginationV3ForPortalSessions--filters {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;
  }

  .select-location-in-TablePaginationV3ForPortalSessions--filters .select__list-container {
    width: 400px;
  }
.datepicker_height_27 {
  height: 27px !important;
}
.switcher-conn-disconn-date-range-filter-in-portal-sessions-filters label {
  height: 27px !important;
  margin: 0px !important;
}

.switcher-conn-disconn-date-range-filter-in-portal-sessions-filters span.switch-handle,
.switcher-conn-disconn-date-range-filter-in-portal-sessions-filters span.switch-label {
  height: 27px !important;
}

.datepicker-in-portal-sessions-filters .mx-input-wrapper {
  max-height: 27px !important;
  overflow: hidden;
}
.datepicker-in-portal-sessions-filters .mx-input-wrapper input {
  padding-top: 0px !important;
}
.datefilters-wrapper-in-portal-sessions-filters {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}
/*.small-placehilder-in-portal-sessions-filters{*/
/*  min-height: 1em !important;*/
/*}*/
.multiselect-in-portal-session-filters {
  min-height: 27px !important;
}

.multiselect-in-portal-session-filters .multi-select__control {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  max-height: 300px !important;
  min-height: 27px !important;
  align-items: center;
}

.multiselect-in-portal-session-filters .multi-select__input {
  min-height: 27px !important;
  margin-bottom: 0px !important;
}

.multiselect-in-portal-session-filters .arrow {
  min-height: 27px !important;
}

.multiselect-in-portal-session-filters .multi-select__tag {
  margin-bottom: 0px;
  height: 22px !important;
}

.select-in-portal-session-filters {
  min-height: 27px !important;
}

.select-in-portal-session-filters .select__control {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  max-height: 300px !important;
  min-height: 27px !important;
  align-items: center;
}

.select-in-portal-session-filters .select__input {
  min-height: 27px !important;
  margin-bottom: 0px !important;
}

.select-in-portal-session-filters .arrow {
  min-height: 27px !important;
}

.select-in-portal-session-filters .select__tag {
  margin-bottom: 0px;
  height: 22px !important;
}

.datesBlockInPortalSessionsFilters-enter-active,
.datesBlockInPortalSessionsFilters-leave-active {
  transition: opacity 0.3s;
}

.datesBlockInPortalSessionsFilters-enter,
.datesBlockInPortalSessionsFilters-leave-to {
  opacity: 0;
}
.apply-button-when-dates-filter-block-not-shown {
  position: absolute;
  right: 0px;
}
.filters-eq-not-eq {
  font-weight: bold;
}
/*.reset-button{*/
/*  !*height: 25px !important;*!*/
/*}*/
</style>
